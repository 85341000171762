//React
import React from 'react'

//Packages
import Helmet from 'react-helmet'

//Components
import Layout from '../components/layout'
import ContactLeadForm from '../components/marketing/contactLeadForm'

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Layout>
        <div class="flex-grow-1">
          <Helmet
            title="Storylava - Request demo"
            meta={[
              {
                name: 'description',
                content:
                  'See how how Storylava can help you drive your business forward with rich visuals for selling',
              },
            ]}
          />
          <ContactLeadForm
            title="Drive your business forward"
            subtitle="We'd like to show you the Storylava visual selling platform"
            cta="Request demo"
            leadSource={`request-demo`}
          />
        </div>
      </Layout>
    )
  }
}
